
































































import { UserModel } from 'truemarket-modules/src/models/api/users'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DashboardIndex extends Vue {
  private user: UserModel | null = null;

  mounted () {
    this.user = this.$store.state.identity.User
  }
}
